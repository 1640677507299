.card {
    border-radius: 6px;
    border: none;

    &.card-overlay {
        .card-image {
            @include media-breakpoint-up(sm) {
                aspect-ratio: 1.91 / 1;
            }
        }

        .card-img-overlay {
            padding: 0 0 15px;
            background-color: unset;
            background-image: linear-gradient(rgba($white, 0) 50%, rgba($black, .75));
            
            @include media-breakpoint-up(lg) {
                padding: 0 0 35px;
            }

            display: flex;
            align-items: flex-end;
        }

        .card-caption {
            .card-title {
                font-size: $h2-font-size;
                background-color: $primary;
                padding: 1rem 1.5rem 1rem 0;
                margin-bottom: 10px;
                max-width: max-content;

                &:hover {
                    color: $white;
                }

                @include media-breakpoint-up(lg) {
                    padding: 1rem 3rem 1rem 0;
                }
            }

            .card-subtitle {
                margin-top: 0;
            }

            .card-title,
            .card-subtitle,
            .card-description {
                padding-left: 1.25rem;
            }

            .card-description {       
                padding-right: .5rem;

                p {
                    margin-bottom: 0;
                }
            }

        }
    }

    .card-description {

		.list-icons {
			display: flex;
			column-gap: 1rem;
			.list-item {
                display: flex;
                align-items: center;
                column-gap: .25rem;
				padding: 0;
				.icon {
					position: static !important;
					font-size: 18px;

					i {
						font-weight: 400;
					}
				}
				.label {
                    width: 2ch;
                    height: 2ch;
                    overflow: hidden;
                    transform: translateY(-3px);
                    color: $primary-dark;
				}
			}
		}
	}

    &.card-assortiment {
        .card-caption {
            .card-prices {
                margin-top: 0;
                margin-bottom: 0.75rem;

                .card-price-value.forsale {
                    font-weight: 400;
                    opacity: .5;
                }
            }
        }
    }

    &.card-arrangement {
        .card-image {
            .card-label {
                background-color: $red;
                color: $white;
                padding: .5rem 1.25rem;
                line-height: normal;
                right: auto;
                top: 1.5rem;
                bottom: auto;
                margin: 0;
                border-radius: 0;

                &::after {
                    display: none;
                }
            }
        }

        .card-info {
            .card-staydata {
                flex-direction: column;

                li + li::before {
                    display: none;
                }
            }
        }
    }
}