// header
.header {
	background: $white;
	transition: 0.5s;
	position: sticky;
	z-index: 998;
	top: 0;
	left: 0;
	right: 0;

	&.sticky {
		box-shadow: $shadow;
	}

	.container-fluid {
		.container-holder {
			>.column {
				padding-left: 30px;
				padding-right: 30px;
				@include media-breakpoint-up(lg) {
					padding-left: 40px;
					padding-right: 40px;
				}
			}
		}
	}

	// Top bar
	.top-bar {
		background-color: $primary-dark;

		.owl-carousel {
			flex: 0 0 calc(100% - 60px);
			max-width: calc(100% - 60px);

			.owl-stage {
				margin: auto;
			}

			.item {
				.owl-caption {
					.owl-title {
						font-size: $small-font-size;
					}
				}
			}
		}

		.card {
			flex-direction: row;
			align-items: center;
			background-color: transparent;
			border: none;

			.card-image {
				font-size: $small-font-size;
				color: $primary;
			}

			.card-body {
				padding: 10px 15px;
			}

			.card-title {
				color: $white;
				font-size: $small-font-size;
				margin: 0;
			}

			.card-buttons {
				display: none;
			}
		}
	}

	// Bottom bar
	.bottom-bar {
		padding-top: 3px;
		padding-bottom: 3px;

		&.container-fluid {
			.container-holder {
				> .column {
					justify-content: space-between;
				}
			}
		}

		.btn-back {
			margin-bottom: 0 !important;
			font-size: $small-font-size;
			text-decoration: unset !important;

			&:hover {
				color: $primary-dark !important;
			}
		}
	}


	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-left: auto;

		.navbar-toggler {
			font-size: 1.5rem;
			color: $primary-dark;
			padding: 0.25rem 1.25rem;

			&[aria-expanded=false] {
				.navbar-toggler-icon {
					.fa-times {
						display: none;
					}
				}
			}

			&[aria-expanded=true] {
				color: $primary-dark;
				.navbar-toggler-icon {
					.fa-bars {
						display: none;
					}
				}
			}
		}
	}

	// logo
	.logo {
		margin: .5rem 10px .5rem 0;

		@include media-breakpoint-down(sm) {
			max-width: 126px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		.navbar-nav {
			.nav-item {
				white-space: nowrap;
				.nav-link {
					padding-right: .75rem !important;
					padding-left: .75rem !important;
					color: $primary-dark !important;
					font-weight: bold;
				}

				&.active,
				&:hover {
					.nav-link {
						color: $primary !important;
					}
				}
			}
		}

		@include media-breakpoint-down(lg) {
			order: 3;
		}

		@include media-breakpoint-up(xl) {
			justify-content: center;

			.navbar-nav {
				column-gap: 30px;
			}

		}
	}

	// Bottom menu
	.menu.bottom-menu {

		@include media-breakpoint-up(sm) {
			margin-left: auto;
			display: flex !important;
			flex-basis: auto;
			flex-grow: 0;
			.navbar-nav {
				column-gap: 1.5rem;
				flex-direction: row !important;
				.nav-item {
					font-size: $small-font-size;

					.nav-link {
						color: $white !important;
						text-decoration: none;
						font-weight: 400;
					}

					&.active,
					&:hover {
						.nav-link {
							color: $primary-dark !important;
						}
					}
				}
			}

		}
	}

	// site-switcher
	.site-switcher {
		flex: 0 0 60px;
		.navbar-nav.language {
			justify-content: center;
			.nav-item {
				&.active {
					display: none;
				}

				.nav-link {
					color: $white;
					border: 1px solid $white;
					border-radius: 50%;
					font-size: $font-size-base;

					&::after {
						display: none;
					}

					&:hover {
						box-shadow: none;
						transform: translateY(-4px);
					}
				}

				.dropdown-menu {
					.dropdown-item {
						img {
							margin-right: .5rem;
							border-radius: 99px;
							width: 20px;
							height: 20px;
							object-fit: cover;
						}

						.language-name {
							font-size: $small-font-size;
						}
					}
				}
			}
		}
	}

	.header-icons {
		display: none;

		@include media-breakpoint-up(sm) {
			display: block;
		}

		ul {
			gap: 1.5rem;
	
			li {
				font-size: 1.25rem;
				width: 1.25rem;
				text-align: center;
				color: $primary-dark;
	
				a {
					color: $primary-dark;
					&:hover {
						color: $primary;
					}
				}
	
	
				.list-item-title {
					display: none;
				}
			}
		}
	}
}

// hide btn-back on specific templates
&.home,
&.contact,
&.bundle-overview {
	.back-to-overview-link {
		display: none;
	}
}