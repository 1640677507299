.owl-carousel {

    &.slider {

        .owl-nav {
            .owl-next,
            .owl-prev {
                font-size: 24px;
                width: 24px;
                line-height: 24px;
            }
    
            .owl-next {
                right: -28px;
            }

            .owl-prev {
                left: -28px;
            }
        }

    }


    .owl-dots {
        .owl-dot {
            span {
                width: 20px;
                height: 5px;
                border-width: 1px;

                @include media-breakpoint-up(md) {
                    width: 40px;
                }
            }
        }
    }
}