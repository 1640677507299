.collection {
	// inspiration
    &.inspiration {
        margin-bottom: 0 !important;
        .card {
            &.card-overlay {

                .card-img-overlay {
                    @include media-breakpoint-up(lg) {
                        padding: 0 0 25px;
                    }
                }

                .card-image {
                    min-height: 340px;
                    width: 100%;

                    img {
                        height: 100%;
                    }
                }

                .card-caption {
                    .card-title {
                        font-size: $h5-font-size;
                        
                        @include media-breakpoint-up(lg) {
                            font-size: $h4-font-size;
                        }
                    }
                }
            }
        }
    }

	&.inspiration-1 {
		@include make-row();

		margin: 0 -15px -30px -15px;

		.item {
			@include make-col-ready();
			@include make-col(12);

			margin: 0 0 30px 0;

			@include media-breakpoint-up(sm) {
				@include make-col(12);
			}

			@include media-breakpoint-up(md) {
				@include make-col(12);
			}

			@include media-breakpoint-up(lg) {
				@include make-col(12);
			}

			@include media-breakpoint-up(xl) {
				@include make-col(12);
			}

            .card {
                &.card-overlay {
                    .card-image {
                        @include media-breakpoint-down(lg) {
                            min-height: 500px;
                        }
                    }
                    .card-caption {
                        .card-title {
                            font-size: $h5-font-size;
                        
                            @include media-breakpoint-up(lg) {
                                font-size: $h2-font-size;
                            }
                        }
                    }
                }
            }
		}
	}

    // solid-cards
    &.solid-cards {
        .grid-items {
            margin-bottom: 0 !important;

            .item {

                .card {
                    background-color: $primary-dark;
                    min-height: 180px;

                    @include media-breakpoint-up(lg) {
                        min-height: 220px;
                    }

                    .card-image {
                        display: none;
                    }

                    .card-caption {
                        .card-title {
                            font-size: $h5-font-size;
                            
                            @include media-breakpoint-up(lg) {
                                font-size: $h4-font-size;
                            }
                            color: $white;
                            &:hover {
                                color: $white;
                            }

                            &::after {
                                content: '\f054';
                                font-family: $font-awesome;
                                font-size: $h5-font-size;
                                font-weight: 400;
                                margin-left: .5rem;
                            }
                        }

                        .card-description {
                            &-content {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    // collection-icons
    &.collection-icons {
        .grid-items {
            .item {
                &:not(:last-child) {
                    .card {
                        padding-bottom: 1.25rem;
                        border-bottom: 1px solid $primary-dark;
                    }
                }

                @include media-breakpoint-up(sm) {
                    &:not(:last-child) {
                        .card {
                            padding-bottom: 0;
                            border-bottom: none;
                        }
                    }

                    &:nth-child(1),
                    &:nth-child(3) {
                        border-right: 1px solid $primary-dark;
                    }
                }

                @include media-breakpoint-up(lg) {
                    @include make-col(3);

                    &:nth-child(2) {
                        border-right: 1px solid $primary-dark;
                    }
                }
    
                @include media-breakpoint-up(xl) {
                    @include make-col(3);
                }
            }
        }
        .card {
            border: none;
            border-radius: 0;
            
            &-image {
                aspect-ratio: unset;
                text-align: left !important;

                i {
                    font-size: $h3-font-size;
                    color: $primary;
                }
            }

            &-body {
                padding: .5rem 0;
            }

            &-caption {
                .card-title {
                    color: $primary-dark;
                    font-size: $h6-font-size;
                    margin-bottom: .25rem;
                }

                .card-description {
                    font-size: $small-font-size;

                    p {
                        margin-bottom: 0;
                    }
                }
            }

            &-buttons {
                display: none;
            }
        }
    }
}
