&.accommodation-category-detail {
	.bundle-overview-section {
		background-color: $light;
	}
}

&.accommodation-detail {

    .container-two-columns {
        .container-holder {
            > .column.one {
                @extend .pr-lg-3;
            }
            > .column.two {
                @extend .pl-lg-3;
            }
        }
    }
	.eyecatcher {
		display: none;
	}

    .lead-section {
        padding-bottom: 0;

		.info-action {
			.btn {
				@include media-breakpoint-down(sm) {
					padding: .5rem;
					font-size: 13px;
				}
			}
		}
    }


	.share-item {
		position: relative;

		.share-btn {
            color: $primary-dark;
			i {
				margin-left: 10px;
			}
		}

		// platforms-list
		.platforms-list {
			padding: 0;
			margin: 0;
			list-style: none;
			position: absolute;
			top: -1rem;
			left: 50%;
			z-index: -1;
			opacity: 0;
			transform:translateX(-50%);
			transition: all 0.5s ease-in-out;

			&-item {
				margin-bottom: 5px;

				&-link {
					display: block;
					text-decoration: none;

					i {
						width: 2rem;
						height: 2rem;
						line-height: 2rem;
						border-radius: 50%;
						text-align: center;
						background-color: $white;
						color: $primary;

						&:hover {
							color: $white;
							background-color: $primary;
						}
					}
				}
			}

			&.open {
				z-index: 999;
				top: 50px;
				opacity: 1;
			}
		}
	}

    .subtitle {
        h2 {
            font-size: $small-font-size;
            font-weight: 400;
            color: $primary;
        }
    }

    .desc {
        color: $black;

        @include media-breakpoint-up(lg) {
            max-width: 75%;
        }
    }

	.footer-faq {
		display: none;
	}
}

&.accommodation-search-book {
	.content-section {
		background-color: $light;
	}

	.lead-section {

		.column {
			padding-bottom: 2rem;
		}

		div.zoek-en-boek-label {
			@extend .global-label;

			right: 0;
			
			@include media-breakpoint-down(md) {
				bottom: -90px;
			}
		}
	
		&.winter {
			.zoek-en-boek-label {
				li {
					&:first-child {
						display: block;
					}
				}
			}
		}
	
		&.summer {
			.zoek-en-boek-label {
				li {
					&:last-child {
						display: block;
					}
				}
			}
		}
	}

}