// footer
.footer {
    background-color: $primary-dark;
    color: $white;

    a {
        color: $white;
        &:hover {
            color: $primary;
        }
    }

    // footer-outro
    .footer-outro {
        padding: 1rem 0;
        .container-two-columns {
            .container-holder {
                > .column{
                    justify-content: center;
                    @extend .py-3;
                
                    &.one {
                        margin-left: auto;
                        margin-right: auto;
                        @extend .text-center;
                        @extend .text-sm-left;
                        @extend .col-8;
                        @extend .col-lg-10;
                        @extend .pr-lg-4;
                        @extend .flex-md-row;
                        @extend .align-items-md-center;

                        
                        border-bottom: 1px solid $white; 

                        @include media-breakpoint-up(sm) {
                            border-bottom: none;
                            border-right: 1px solid $white; 
                        }
                    }
                    &.two {
                        @extend .col-sm-4;
                        @extend .col-lg-2;
                        @extend .pl-lg-4;
                    }
                }
            }
        }

        .page-block.link {
            @extend .mt-2;
            @extend .mt-md-0;
            @extend .ml-md-auto;

            .page-block.link {
                @extend .btn;
                @extend .btn-secondary;
                font-size: 1rem;
                padding: .875rem 3rem;
            }
        }

        .page-block.wysiwyg {
            margin-bottom: -1rem;
        }

        p {
            color: $white;
        }
    }

    // footer-faq
    .footer-faq {
        padding: 4.125rem 0;

        .title {
            h2 {
                margin: 0;
            }
        }
    }

    .footer-sitelinks {
        padding: 3rem 0;

        .footer-text {
            h5 {
                color: $white;
            }

            img {
                max-width: 240px;
            }
        }
    }

    // footer-logolink
    .footer-logolink {
        .list {
            font-size: $h4-font-size;
            column-gap: 15px;
            justify-content: center;
            .list-item {
                .link {
                    width: 1.5rem;
                    display: block;
                    text-align: center;
                }
            } 
        }
    }

    // footer-buttons
    ul.footer-buttons {
        flex-direction: column;
        row-gap: 10px;
        li {
            a {
                display: block;
                background-color: $primary;
                border-radius: 4px;
                padding: 4px 10px;
                text-decoration: none;
                max-width: 224px;

                &:hover {
                    background-color: $white;
                    color: $primary-dark;
                }

                .list-item-title {
                    font-size: 12px;
                }

                .list-item-suffix {
                    font-size: $small-font-size;
                    font-weight: bold;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    // footer-copyright
    .footer-copyright {
        padding: 3.875rem 0 1.5rem;
        .container-holder {
            justify-content: center;
            @include media-breakpoint-down(sm) {
                padding: 0 1rem;
            }
        }
        ul {
            column-gap: 1.25rem;
            align-items: center;
            font-size: $small-font-size;

            a {
                text-decoration: none;
            }
        }
    }
}
