&.privacy-statement,
&.disclaimer {

    .lead-section,
    .content-section {
        .container-one-column {
            .container-holder {
                max-width: $max-content-size;
                margin: 0 auto;
            }
        }
    }

    div.zoek-en-boek-label {
        display: none;
    }
}

&.one-column-page {
    .lead-section + .content-section {
        padding-top: 0;
    }

    .content-section {
        &:has(.collection) {
            @extend .bg-light;

            padding-top: 3rem;
        }

        // collection grid
        .collection {
            margin: 1rem 0;
            &.grid {
                .grid-items {
                    justify-content: flex-start;

                    .item {
                        .card {
                            @extend .default-card;
                        }
                    }
                }
            }
        }
    }

    div.zoek-en-boek-label {
        display: none;
    }
}