.gallery,
.gallery-horizontal {
	margin: 0;
	border-radius: 15px;
	overflow: hidden;

	.gallery-item {
		padding: 0 2px 2px 0;
		margin: 0;

		&.large-item {
			padding: 0 2px 0 0;
			margin: 0;
		}
	}

	.gallery-thumbs {
		margin: 0 -2px -2px 0;
	}

	.gallery-label {
		top: 1.5rem;
		background-color: $red;
		color: $white;
		padding: 0.5rem 1.25rem;
		line-height: normal;
		font-weight: bold;
	}
}
