&.contact {

    .mini-sab {
        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    .lead-section + .content-section {
        padding-top: 0;
    }
}