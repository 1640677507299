// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #7AC143;
$green-dark: #49851A;
$green-light: #F0F3EE;
$blue: #00A4D9;
$blue-dark: #00688A;
$blue-light: #EAF3F6;
$white: #fff;
$black: #000;
$red: #D9534F;

// theme-colors
$primary: var(--w3-primary-color);
$primary-light: var(--w3-primary-light-color);
$primary-dark: var(--w3-primary-dark-color);
$secondary: var(--w3-secondary-color);
$secondary-light: var(--w3-secondary-light-color);
$secondary-dark: var(--w3-secondary-dark-color);

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk&display=swap');

$font-family-primary: "Hanken Grotesk", sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base * 1.125; // 18px

$small-font-size: $font-size-base * 0.875; // 14px

$headings-font-family: $font-family-primary;
$headings-font-weight: 700;
$headings-color: $primary-dark;

// contextual
$light: $primary-light;
$dark: $primary;
$text-light: $white;
$text-dark: $primary-dark;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: 0 3px 6px rgba($black, 0.3);

// sizes
$max-content-size: 800px;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
