// container
.container {
	@include media-breakpoint-down(xs) {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 856px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
	}

	&.container-outro {
		max-width: 720px;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.3s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border-radius: 6px;
	border: none;

	.card-image {
		.card-image-caption {
			right: auto;
			top: 1.5rem;
			bottom: auto;

			.card-image-label {
				background-color: $red;
				color: $white;
				padding: .5rem 1.25rem;
				line-height: normal;
			}
		}
	}

	.card-body {
		padding: 1.5rem 1.25rem;

		.card-caption {
			display: flex;
			flex-direction: column;
		}
	}

	.card-title-link {
		order: 2;
		margin-bottom: 1rem;

		.card-title {
			color: $primary-dark;
			font-size: $h5-font-size;
		}
	}

	.card-subtitle {
		order: 1;
		font-size: $small-font-size;
		color: $primary-dark;
		font-weight: 400;
		margin-bottom: 0;
		margin-top: 0;
	}

	.card-text {
		order: 3;
		font-size: $small-font-size;
	}

	.card-description {
		order: 3;

		.card-description-content {
			font-size: $small-font-size;
		}

		.list-icons {
			.list-item {
				.icon {
					i {
						color: $primary-dark;
					}
				}
			}
		}
	}
}
