
.form {
    background-color: $white;
    color: $black;
    border: none;
    border-radius: 6px;
    padding: 2.875rem;
    font-size: $small-font-size;

    .form-control {
        background-color: $primary-light;
        border-color: $primary-light;
    }

    textarea {
        min-height: 160px;
        resize: none;
    }
}