.global-label {
    // banner-label
    position: absolute;
    bottom: 120px;
    right: 30px;
    z-index: 3;
    
    @include media-breakpoint-up(lg) {
        bottom: auto;
        top: 30px;
        right: 80px;
    }

    li {
        padding: 0 .9rem;
        background-color: $secondary;
        width: 125px;
        height: 125px;
        border-radius: 50%;
        text-align: center;
        display: none;

        @include media-breakpoint-up(lg) {
            padding: 0 1.25rem;
            width: 150px;
            height: 150px;
        }

        a {
            color: $white;
            text-decoration: none;
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            height: 100%;

            i {
                font-size: $h2-font-size;
                margin-bottom: 2px;
                
                @include media-breakpoint-up(lg)  {
                    font-size: $h1-font-size;
                    margin-bottom: .5rem;
                }
            }

            .list-item-title {
                font-size: $font-size-base;
                font-weight: bold;
                line-height: 1.2;
            }

            .list-item-suffix {
                font-size: 10px;
                line-height: 12px;
                
                @include media-breakpoint-up(lg)  {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }
}