// btn
.btn {
	font-weight: 700;
	font-size: $small-font-size;
	padding: .5rem 1.25rem;
	margin-bottom: .5rem;

	&:focus {
		box-shadow: none;
	}

	// btn-primary
	// &.btn-primary {
	// 	&:hover {}
	// }

	// btn-outline-primary
	// &.btn-outline-primary {
	// 	&:hover {}
	// }

	// btn-secondary
	&.btn-secondary {
		background-color: $primary-dark;
		border-color: $primary-dark;
		&:hover {
			background-color: darken($primary-dark, 5%);
			border-color: darken($primary-dark, 5%);
		}
	}

	// btn-secondary-channel
	&.btn-secondary-channel {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
		&:hover {
			background-color: darken($secondary, 5%);
			border-color: darken($secondary, 5%);
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		border-color: $primary-dark;
		color: $primary-dark;
		&:hover {
			background-color: $primary-dark;
			color: $white;
		}
	}

	// btn-outline-white
	&.btn-outline-white {
		border-color: $white;

		&:hover {
			background-color: $white;
			color: $primary-dark;
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-outline-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	background-color: $red;
	border-color: $red;
	color: $white;
	
	&:hover {
		background-color: darken($red, 20%);
		border-color: darken($red, 20%);
		color: $white;
	}

	&::after {
		content: "\f078";
		font-weight: 900;
		font-family: $font-awesome;
		margin-left: 1rem;
	}
}
