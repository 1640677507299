// lead-section
// =========================================================================
.lead-section {
	padding: 3.125rem 0;

	@include media-breakpoint-up(sm) {
		padding: 3.5rem 0;
	}

	// bundles
	.info-header {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-end;
		@extend .justify-content-between;
	}

	.info-action {
		@extend .d-flex;
		@extend .flex-row-reverse;
		@extend .flex-md-row;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-end;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-start;
			@extend .list-unstyled;

			li {
				margin-right: 1rem;
			}

			.icon {
				margin-right: 5px;

				i {
					font-weight: 400;
					font-size: $h6-font-size;
					color: $primary-dark;
				}
			}

			// .label {}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	padding: 3.125rem 0;

	@include media-breakpoint-up(sm) {
		padding: 3.5rem 0;
	}

	// title
	.page-title,
	.title {
		h2 {
			margin-bottom: 1.25rem
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	padding: 3.125rem 0;

	@include media-breakpoint-up(sm) {
		padding: 3.5rem 0;
	}

	// title
	.page-title,
	.title {
		h2 {
			margin-bottom: 1.25rem
		}
	}
}

// banner-section
// =========================================================================
// .banner-section {}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 3.125rem 0;

	@include media-breakpoint-up(sm) {
		padding: 3.5rem 0;
	}

	// title
	.page-title,
	.title {
		h2 {
			margin-bottom: 1.25rem
		}
	}

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 3.125rem 0;

	@include media-breakpoint-up(sm) {
		padding: 3.5rem 0;
	}
}

// gallery-section
// =========================================================================
.gallery-section {
	padding: 1rem 0 3.125rem;

	@include media-breakpoint-up(sm) {
		padding: 1rem 0 3.5rem;
	}
}

// booking-section
// =========================================================================
.booking-section {
	padding: 3.125rem 0;

	@include media-breakpoint-up(sm) {
		padding: 3.5rem 0;
	}

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	padding: 3.125rem 0;

	@include media-breakpoint-up(sm) {
		padding: 3.5rem 0;
	}
}
