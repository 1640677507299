&.faq-overview {

    .bundle-overview-section {
        .container-one-column {
            .container-holder {
                max-width: $max-content-size;
                margin: 0 auto;
            }
        }
    }

    .footer-faq {
		display: none;
	}
}