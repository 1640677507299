// mini-sab
.mini-sab {
	.container {
		max-width: 968px;
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			flex: 1;
			z-index: 100;
			position: relative;
			margin: -4rem 0 0;
			padding: 1.5rem 1.875rem;
			border-radius: 0 4px 4px 4px;
			background: $white;
			box-shadow: 0 0 20px rgba($black, 0.29);
			color: $white;

			@include media-breakpoint-up(sm) {
				margin: -6rem 0 0;
			}

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -70px;
				padding-left: 1.5rem;
				padding-right: 1.5rem;
			}

			.column {
				padding: 0;
			}

			// above eyecatcher
			/* @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
			} */
		}

		// tabs
		div.tabs {
			--w3-items-height: 54px;
			position: absolute;
			top: calc(-1.5rem - var(--w3-items-height) + 1px); // - container-holder padding - items height
			left: -1.875rem;

			@include media-breakpoint-up(lg) {
				left: -1.5rem;
			}

			ul {
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;

				li {
					margin-right: 5px;
					order: 0;

					@include media-breakpoint-down(md) {
						margin-right: 3px;
					}

					a {
						display: flex;
						align-items: center;
						height: var(--w3-items-height);
						padding: 0 1.5rem;
						background-color: $secondary-light;
						border: 1px solid $secondary-light;
						border-bottom: 1px solid $white;
						border-radius: 4px 4px 0 0;
						color: $secondary-dark;
						font-size: 14px;
						text-decoration: none;

						&:hover {
							border-color: $secondary-dark;
							border-bottom: 1px solid $white;

							i {
								transform-origin: center center;
								transform-box: fill-box;
								animation: weel 3s linear 0s infinite forwards;
							}
						}

						.list-item-title {
							margin-left: 10px;
							font-weight: bold;
						}

						i {
							width: 13px;
							height: 13px;
							font-size: 13px;
							text-align: center;
							color: $secondary;
						}
					}

					&:first-child {
						a {
							background-color: $white;
							color: $primary-dark;

							i {
								color: $primary;
							}

							&:hover {
								border-color: $primary-dark;
								border-bottom: 1px solid $white;
							}
	
						}
					}
				}

			}
			&.summer {
				ul {
					li {
						a {
							background-color: $white;
							border: 1px solid $primary-light;
							border-bottom: 1px solid $white;
							color: $primary-dark;
	
							&:hover {
								border-color: $primary-dark;
								border-bottom: 1px solid $white;
							}
	
							i {
								color: $primary;
							}
						}

						&:first-child {
							order: 1;
							a {
								background-color: $secondary-light;
								color: $secondary-dark;
	
								i {
									color: $secondary;
								}
	
								&:hover {
									border-color: $secondary-dark;
									border-bottom: 1px solid $white;
								}
		
							}
						}
					}
				}
			}
		}

		// mini-search-book
		.mini-search-book {
			position: relative;
		}
	}
}

&.home,
&.contact,
// &.service-overview,
&.service-detail,
&.blog-post-overview,
&.assortiment-overview,
&.assortiment-detail,
&.faq-overview {
	// mini-sab
	.mini-sab {
		background-color: $primary-light;
	}
}

// hide mini-sab
&.accommodation-category-overview,
&.accommodation-category-detail,
&.accommodation-overview,
&.accommodation-search-book,
&.accommodation-book,
&.accommodation-book-error,
&.accommodation-book-succes,
&.accommodation-book-terms-and-conditions,
&.accommodation-detail {
    .mini-sab {
        display: none;
    }
}