&.blog-post-detail {

    .container-two-columns {
        .container-holder {
            > .column.one {
                @extend .pr-lg-3;
            }
            > .column.two {
                @extend .pl-lg-3;
            }
        }
    }

    .lead-section {
        padding-bottom: 0;
    }

    .subtitle {
        h2 {
            font-size: $small-font-size;
            font-weight: 400;
            color: $primary;
        }
    }

    .desc {
        color: $black;

        @include media-breakpoint-up(lg) {
            max-width: 75%;
        }
    }

	.footer-faq {
		display: none;
	}
}
