// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 100vh;
	min-height: 300px;
	max-height: 300px;

	@include media-breakpoint-up(lg) {
		max-height: 540px;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 100vh;
	min-height: 540px;
	max-height: 600px;
}

.eyecatcher.large {
	position: relative;
	.owl-carousel {
		.item {
			align-items: flex-start;
			.owl-caption-holder {
				transform: translateY(2.25rem);
				
				@include media-breakpoint-up(lg) {
					transform: translateY(50%);
				}
				
				.owl-container {
					max-width: 100%;
					padding: 0 !important;
				}
			}

			.owl-caption {
				text-align: left;
				display: flex;
				flex-flow: column wrap;
				text-shadow: none;

				.owl-subtitle {
					order: 0;
					font-size: $h6-font-size;
					margin-left: 30px;
					margin-bottom: 1.25rem;
					max-width: 250px;
					
					@include media-breakpoint-up(lg) {
						font-size: $h4-font-size;
						margin-left: 40px;
						max-width: 550px;
					}
				}

				.owl-title {
					order: 1;
					font-size: $h3-font-size;
					background-color: $primary-dark;
					padding: 1rem;
					padding-left: 30px; 
					max-width: 320px;
					@include media-breakpoint-up(lg) {
						font-size: $h1-font-size;
						padding-left: 40px;
						max-width: 550px;
					}
				}
			}
		}
	}

	div.banner-label {
		@extend .global-label;
	}

	&.winter {
		.banner-label {
			li {
				&:first-child {
					display: block;
				}
			}
		}
	}

	&.summer {
		.banner-label {
			li {
				&:last-child {
					display: block;
				}
			}
		}
	}
}
