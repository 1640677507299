// new base file - january 2022

#TommyBookingSupport {
	font-size: 16px;
	background-color: $white;
	color: $black;
	padding: 2.875rem;
	border-radius: 6px;
	margin-top: .5rem;
	font-size: $small-font-size;

	// @include media-breakpoint-down(xs) {
	// 	margin-left: -15px;
	// 	margin-right: -15px;
	// }
}

// colors
#TommyBookingSupport .tbs-navbar-default {
	background-color: $primary-light;
	color: $primary-dark;
	border-color: $primary-light;
	border-radius: 0;
	padding: 0.5rem 10px;
}

#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > li > a {
	padding: 0.25rem 0.5rem;
}

#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > li > a:not([data-page="overzicht"]) {
	border-right: 1px solid $primary-dark;
}

#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:hover {
	color: $primary;
	background-color: $primary-light;
}
#TommyBookingSupport .tbs-btn-info {
	background-color: $primary;
	border-color: $primary;
	color: $white;
}
#TommyBookingSupport .tbs-btn-info:hover {
	background-color: $primary-dark;
	border-color: $primary-dark;
	color: $white;
}
#TommyBookingSupport .tbs-btn-success {
	background-color: $primary;
	border-color: $primary;
	color: $white;
	max-width: 190px;
	margin-left: auto;
	margin-right: auto;
}
#TommyBookingSupport .tbs-btn-success:hover {
	background-color: $primary-dark;
	border-color: $primary-dark;
	color: $white;
}
#TommyBookingSupport .tbs-btn-danger {
	background-color: $red;
	border-color: $red;
	color: $white;
}
#TommyBookingSupport .tbs-btn-danger:hover {
	background-color: darken($red, 10%);
	border-color: darken($red, 10%);
	color: $white;
}

#TommyBookingSupport .tbs-btn-primary {
	background-color: $secondary;
	border-color: $secondary;
	color: $white;
}
#TommyBookingSupport .tbs-btn-primary:hover {
	background-color: $secondary-dark;
	border-color: $secondary-dark;
	color: $white;
}

#TommyBookingSupport .tbs-btn.tbs-disabled {
	border-color: $primary-dark;
	color: $primary-dark;
}
#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
	transition: 0.5s;
}

#TommyBookingSupport .tbs-calendarPrevious,
#TommyBookingSupport .tbs-calendarNext {
	font-size: 1.25rem;
	padding: 3px;
}

#TommyBookingSupport .tbs-calendarPrevious .tbs-glyphicon-chevron-left::before {
	content: "\f060";
	font-family: $font-awesome;
	font-weight: 400;
}

#TommyBookingSupport .tbs-calendarNext .tbs-glyphicon-chevron-right::before {
	content: "\f061";
	font-family: $font-awesome;
	font-weight: 400;
}

// manually include tommy base styling
// source: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
#TommyBookingSupport .tbs-container-fluid {
	padding: 0;
}
#TommyBookingSupport .tbs-navbar-collapse {
	padding: 0;
}
#TommyBookingSupport .tbs-navbar-nav > li > a {
	padding-top: 10px;
	padding-bottom: 10px;
}

#TommyBookingSupport .tbs-navbar {
	min-height: auto;
	overflow: hidden;
}
#TommyBookingSupport * a {
	text-decoration: none;
}
#TommyBookingSupport .tbs-legenda {
	background: none;
	border: none;
}

// #TommyBookingSupport .tbs-row:has(.tbs-persoonscategorie) {
// 	flex-direction: column;
// }

#TommyBookingSupport .tbs-row .tbs-persoonscategorie {
	white-space: nowrap;
}

#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport .tbs-row.tbs-page-overzicht > .tbs-container-fluid > .tbs-row:nth-child(2) {
	padding: 0;
	background-color: $white; // custom
	border: none; // custom
	border-radius: 0;
}

#TommyBookingSupport .tbs-row.tbs_kalenders {
	margin-top: 20px;
}

#TommyBookingSupport .tbs-row.tbs_kalenders .tbs-col-xs-12 .tbs-col-md-8 {
	padding: 0;
}

#TommyBookingSupport .tbs-person-details {
	margin-top: 20px;
}

#TommyBookingSupport .tbs-container-fluid.tbs_kassabon {
	margin: 0;
	padding: 0 15px;
}
#TommyBookingSupport .tbs_overlay-mask {
	border-radius: 5px;
}

#TommyBookingSupport .tbs-calendar {
	margin: 20px 0 0;
}
#TommyBookingSupport .tbs-calendar .tbs-day {
	border-radius: 0;
	background-color: $green;
}
#TommyBookingSupport .tbs-calendar .tbs-day div {
	font-size: $small-font-size;
	// color: $black;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-verleden,
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-nietbeschikbaar {
	background-color: #f3f3f3;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-verleden div,
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-nietbeschikbaar div {
	color: $black;
	opacity: .5;
}

#TommyBookingSupport .tbs-legenda .tbs-day.tbs-beschikbaar,
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar {
	cursor: pointer;
	transition: all 0.5s ease;
	background-color: $green;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover {
	background-color: $blue;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover div {
	color: #fff;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar div {
	text-decoration: none;
	transition: all 0.2s ease;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover {
	opacity: 0.8;
}
#TommyBookingSupport .tbs-calendar table > thead > tr > th {
	border: none;
	font-weight: 400;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
	color: #fff;
	background-color: $blue !important;
}

#TommyBookingSupport .tbs-legenda .tbs-day.tbs-bezet:not(.tbs-verleden),
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-bezet:not(.tbs-verleden),
// #TommyBookingSupport .tbs-calendar .tbs-day.tbs-vertrekdag-bezet,
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-vertrekdag-bezet::after,
// #TommyBookingSupport .tbs-calendar .tbs-day.tbs-aankomstdag-bezet,
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-aankomstdag-bezet::after {
	background-color: $red !important;
}

// #TommyBookingSupport .tbs-calendar .tbs-day.tbs-bezet div {
// 	color: #fff;
// }

#TommyBookingSupport .tbs-row h2 {
	margin-bottom: 0;
	font-size: $font-size-base;
} // custom
#TommyBookingSupport .tbs-row h4 {
	color: $body-color;
	font-size: $small-font-size;
	font-weight: $font-weight-base;
	font-family: $font-family-base;
} // custom
#TommyBookingSupport .tbs-btn-block + .tbs-btn-block {
	margin: 0;
}

#TommyBookingSupport .tbs_overzicht .tbs-row h2,
#TommyBookingSupport .tbs-page .tbs-row h2 {
	margin-bottom: 15px;
}

@include media-breakpoint-up(lg) {
	#TommyBookingSupport .tbs-page {
		padding: 0 15px;
	}
}

#TommyBookingSupport .tbs-form-control {
	background-color: $primary-light;
	border-radius: 3px;
	border: none;
	color: $black;
	box-shadow: none;
}

#TommyBookingSupport .tbs_kalenders #maanden {
	text-align: center;
	-moz-appearance: none; /* Firefox */
	-webkit-appearance: none; /* Safari and Chrome */
	appearance: none;
	height: 36px;
}

// #TommyBookingSupport .tbs_overzicht .tbs-specificaties .tbs-row { margin-top: 15px; }

#TommyBookingSupport .tbs-alert-danger {
	color: #e91e63;
	background-color: rgba(233, 30, 99, 0.09);
	border: none;
	margin: 0 15px 15px;
	padding: 10px 35px 10px 10px;
}
#TommyBookingSupport .tbs-has-error .tbs-form-control {
	border-color: rgb(244, 67, 54);
}
#TommyBookingSupport .tbs_totaal_overzicht .tbs-row {
	margin-bottom: 20px;
}
#TommyBookingSupport .tbs_totaal_overzicht .tbs-accommodatie-image img,
#TommyBookingSupport .mobileonly img {
	aspect-ratio: 3 / 2;
	border-radius: 6px;
}

/* kassabon */

#TommyBookingSupport .tbs_kassabon .tbs_kassabon_overzicht .tbs-row:not(.tbs-specificaties) > div {
	padding: 0;
}


#TommyBookingSupport .tbs_kassabon .tbs_kassabon_overzicht h2 {
	margin: 20px 0;
}
#TommyBookingSupport .tbs_kassabon .tbs-row.tbs-hr {
	display: flex;
	margin-bottom: 20px;
}

#TommyBookingSupport label {
	margin-bottom: 0;
}
#TommyBookingSupport .tbs-label {
	font-size: 100%;
	margin-right: 5px;
}
#TommyBookingSupport .tbs_kassabon .tbs-hr {
	border-top: 1px solid #e7e7e7;
	padding-top: 20px;
	margin-top: 0;
}

#TommyBookingSupport .tbs_persoonsgegevens_overzicht > .tbs-row,
#TommyBookingSupport .tbs_overig_overzicht > .tbs-row,
#TommyBookingSupport .tbs_gasten_overzicht > .tbs-row,
#TommyBookingSupport .tbs_medegasten_overzicht > .tbs-row {
	margin: 0 0 10px 0;
}

@include media-breakpoint-down(lg) {
	#TommyBookingSupport .tbs_overig .tbs-row .tbs-col-xs-6.tbs-naam {
		width: 100%;
	}
	#TommyBookingSupport .tbs_overig .tbs-row .tbs-col-xs-6 {
		width: 100%;
	}

	#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
	#TommyBookingSupport .tbs-row.tbs_accommodaties,
	#TommyBookingSupport .tbs-row.tbs_kalenders,
	#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
	#TommyBookingSupport .tbs-row.tbs_artikelen,
	#TommyBookingSupport .tbs-row.tbs_persoon,
	#TommyBookingSupport
		.tbs-row.tbs-page-overzicht
		> .tbs-container-fluid
		> .tbs-row:nth-child(2) {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	#TommyBookingSupport label {
		margin-bottom: 0.5rem;
	}

	#TommyBookingSupport .tbs-calendarPrevious,
	#TommyBookingSupport .tbs-calendarNext {
		font-size: $small-font-size;
		padding: 6px 12px;
	}


	#TommyBookingSupport .tbs_kassabon .tbs_totaal_overzicht .tbs-totaal {
		background-color: $primary-dark;
	}

	#TommyBookingSupport .tbs_kassabon .tbs_kassabon_toggle i {
		font-size: $font-size-base !important;
	}

	#TommyBookingSupport .tbs_kassabon .tbs_kassabon_toggle {
		transform: rotateZ(90deg);
		display: inline-block !important;
		margin-left: 5px;
	}
}

#TommyBookingSupport .tbs_talen,
#TommyBookingSupport .tbs_talen-mobileontop {
	display: none;
}

/* resonsive mobile */
@media (max-width: 767px) {
	#TommyBookingSupport {
		padding: 1.25rem;
	}

	
}

#TommyBookingSupport .tbs-row.hide {
	display: none;
}


#TommyBookingSupport .tbs-legenda ul li.tbs-legenda-aankomstdag,
#TommyBookingSupport .tbs-legenda ul li.tbs-legenda-vertrekdag {
	display: none;
}